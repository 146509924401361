/**
 * UtilDownload
 */
export class UtilDownload {
    /**
     * download
     * @param url
     * @param filename
     */
    static download(url, filename) {
        const link = document.createElement('a');
        link.href = url;
        link.download = filename;
        link.click();
    }
}
