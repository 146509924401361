import { NetFetch } from '../Net/NetFetch';
import { UnauthorizedError } from './Error/UnauthorizedError';
import { StatusCodes } from './Status/StatusCodes';
/**
 * Area Types
 */
export var TrackingAreaType;
(function (TrackingAreaType) {
    TrackingAreaType["HOME"] = "home";
})(TrackingAreaType || (TrackingAreaType = {}));
/**
 * Organization
 */
export class Organization {
    /**
     * getOrganizationByUser
     */
    static async getOrganizationByUser() {
        const result = await NetFetch.getData('/json/organization/userlist');
        if (result && result.statusCode) {
            const tresult = result;
            switch (tresult.statusCode) {
                case StatusCodes.OK:
                    if (tresult.list) {
                        return tresult.list;
                    }
                    throw new Error('Grouplist is empty return!');
                case StatusCodes.UNAUTHORIZED:
                    throw new UnauthorizedError();
            }
        }
        return null;
    }
    /**
     * getOrganization
     */
    static async getOrganization() {
        const result = await NetFetch.getData('/json/organization/list');
        if (result && result.statusCode) {
            const tresult = result;
            switch (tresult.statusCode) {
                case StatusCodes.OK:
                    if (tresult.list) {
                        return tresult.list;
                    }
                    throw new Error('Grouplist is empty return!');
                case StatusCodes.UNAUTHORIZED:
                    throw new UnauthorizedError();
            }
        }
        return null;
    }
    static async saveOrganization(org) {
        const result = await NetFetch.postData('/json/organization/save', org);
        if (result && result.statusCode) {
            switch (result.statusCode) {
                case StatusCodes.OK:
                    return true;
                case StatusCodes.UNAUTHORIZED:
                    throw new UnauthorizedError();
            }
        }
        return false;
    }
    /**
     * getOrganizationTrackingArea
     * @param {OrganizationTrackingAreaOrgAndType} org
     * @param {id} id
     */
    static async getOrganizationTrackingArea(org, id) {
        let result;
        if (id) {
            result = await NetFetch.postData('/json/organization/trackingarea/list', {
                id
            });
        }
        else if (org) {
            result = await NetFetch.postData('/json/organization/trackingarea/list', {
                organization: org
            });
        }
        if (result && result.statusCode) {
            const tresult = result;
            switch (tresult.statusCode) {
                case StatusCodes.OK:
                    if (tresult.data) {
                        return tresult.data;
                    }
                    break;
                case StatusCodes.UNAUTHORIZED:
                    throw new UnauthorizedError();
            }
        }
        return null;
    }
    /**
     * Save organization tracking area
     * @param {OrganizationTrackingAreaEntry} org
     */
    static async saveOrganizationTrackingArea(org) {
        const result = await NetFetch.postData('/json/organization/trackingarea/save', org);
        if (result && result.statusCode) {
            switch (result.statusCode) {
                case StatusCodes.OK:
                    return true;
                case StatusCodes.UNAUTHORIZED:
                    throw new UnauthorizedError();
            }
        }
        return false;
    }
}
