import { FormGroup, ModalDialog, ModalDialogType, SelectBottemBorderOnly2 } from 'bambooo';
/**
 * SpeciesMergeModal
 */
export class SpeciesMergeModal extends ModalDialog {
    /**
     * constructor
     * @param elementObject
     */
    constructor(elementObject) {
        super(elementObject, 'speciesmergemodaldialog', ModalDialogType.large);
        /**
         * click save fn
         * @protected
         */
        this._onSaveClick = null;
        const bodyCard = jQuery('<div class="card-body"/>').appendTo(this._body);
        const groupSrcSpecie = new FormGroup(bodyCard, 'Specie source');
        this._specieSourceSelect = new SelectBottemBorderOnly2(groupSrcSpecie);
        const groupDstSpecie = new FormGroup(bodyCard, 'Specie destination');
        this._specieDestinationSelect = new SelectBottemBorderOnly2(groupDstSpecie);
        jQuery('<button type="button" class="btn btn-default" data-dismiss="modal">Close</button>').appendTo(this._footer);
        const btnSave = jQuery('<button type="button" class="btn btn-primary">Save</button>').appendTo(this._footer);
        btnSave.on('click', () => {
            if (this._onSaveClick !== null) {
                this._onSaveClick();
            }
        });
    }
    /**
     * setSpecies
     * @param species
     */
    setSpecies(species) {
        this._specieSourceSelect.clearValues();
        this._specieDestinationSelect.clearValues();
        this._specieSourceSelect.addValue({
            key: '0',
            value: '*Please select a specie*'
        });
        this._specieDestinationSelect.addValue({
            key: '0',
            value: '*Please select a specie*'
        });
        for (const specie of species) {
            const toption = {
                key: `${specie.id}`,
                value: specie.name
            };
            this._specieSourceSelect.addValue(toption);
            this._specieDestinationSelect.addValue(toption);
        }
    }
    /**
     * setSourceSpecie
     * @param specieId
     */
    setSourceSpecie(specieId) {
        this._specieSourceSelect.setSelectedValue(specieId);
    }
    /**
     * getSourceSpecie
     */
    getSourceSpecie() {
        return this._specieSourceSelect.getSelectedValue();
    }
    /**
     * setDestinationSpecie
     * @param speciesId
     */
    setDestinationSpecie(specieId) {
        this._specieDestinationSelect.setSelectedValue(specieId);
    }
    /**
     * getDestinationSpecie
     */
    getDestinationSpecie() {
        return this._specieDestinationSelect.getSelectedValue();
    }
    /**
     * resetValues
     */
    resetValues() {
        this._specieSourceSelect.setSelectedValue('0');
        this._specieDestinationSelect.setSelectedValue('0');
    }
    /**
     * setOnSave
     * @param onSave
     */
    setOnSave(onSave) {
        this._onSaveClick = onSave;
    }
}
