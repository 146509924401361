/**
 * Lang_DE
 */
export class Lang_DE {
    constructor() {
        /**
         * lang content
         * @private
         */
        this._content = {
            'title': 'MWPA',
            'login_title': '<b>MWPA</b>',
            'Sighting': 'Sichtung',
            'Tours': 'Touren',
            'Species': 'Spezies',
            'Add sighting': 'Sichtung hinzufügen',
            'Add new sighting': 'Neue Sichtung hinzufügen',
            'Date': 'Datum',
            'Vehicle': 'Fahrzeug',
            'Driver': 'Fahrer',
            'Group-Size': 'Gruppengröße',
            'Time begin': 'Zeit begin',
            'Time end': 'Zeit Ende',
            'Duration': 'Dauer',
            'Location': 'Ort',
            'Encounter': 'Begegnen',
            'Other species': 'Andere Spezies',
            'Add Specie': 'Spezie hinzufügen'
        };
    }
    /**
     * getLangCode
     */
    getLangCode() {
        return 'de';
    }
    /**
     * getLangTitle
     */
    getLangTitle() {
        return 'Deutsch';
    }
    /**
     * getCountryCode
     */
    getCountryCode() {
        return 'de';
    }
    /**
     * l
     * @param acontent
     */
    l(acontent) {
        if (this._content[acontent]) {
            return this._content[acontent];
        }
        return null;
    }
    /**
     * getClassName
     */
    getClassName() {
        return 'Lang_DE';
    }
}
