import { NetFetch } from '../Net/NetFetch';
import { UnauthorizedError } from './Error/UnauthorizedError';
import { StatusCodes } from './Status/StatusCodes';
/**
 * Tour
 */
export class Tours {
    /**
     * getList
     */
    static async getList(filter) {
        let data = {};
        if (filter) {
            data = filter;
        }
        const result = await NetFetch.postData('/json/tours/list', data);
        if (result && result.statusCode) {
            switch (result.statusCode) {
                case StatusCodes.OK:
                    return result;
                case StatusCodes.UNAUTHORIZED:
                    throw new UnauthorizedError();
            }
        }
        return null;
    }
    /**
     * getTrackingList
     * @param tourId
     */
    static async getTrackingList(tourId) {
        const result = await NetFetch.postData('/json/tours/tracking/list', {
            tour_id: tourId
        });
        if (result && result.statusCode) {
            switch (result.statusCode) {
                case StatusCodes.OK:
                    return result.tracking;
                case StatusCodes.UNAUTHORIZED:
                    throw new UnauthorizedError();
            }
        }
        return null;
    }
}
