import { NetFetch } from '../Net/NetFetch';
import { UnauthorizedError } from './Error/UnauthorizedError';
import { StatusCodes } from './Status/StatusCodes';
/**
 * Species
 */
export class Species {
    /**
     * getList
     */
    static async getList() {
        const result = await NetFetch.getData('/json/species/list');
        if (result && result.statusCode) {
            switch (result.statusCode) {
                case StatusCodes.OK:
                    // eslint-disable-next-line no-case-declarations
                    const response = result;
                    return response.list;
                case StatusCodes.UNAUTHORIZED:
                    throw new UnauthorizedError();
            }
        }
        return null;
    }
    /**
     * save
     * @param specie
     */
    static async save(specie) {
        const result = await NetFetch.postData('/json/species/save', specie);
        if (result && result.statusCode) {
            switch (result.statusCode) {
                case StatusCodes.OK:
                    return true;
                case StatusCodes.UNAUTHORIZED:
                    throw new UnauthorizedError();
            }
        }
        return false;
    }
    /**
     * merge
     * @param merge
     */
    static async merge(merge) {
        const result = await NetFetch.postData('/json/species/merge', merge);
        if (result && result.statusCode) {
            switch (result.statusCode) {
                case StatusCodes.OK:
                    return true;
                case StatusCodes.UNAUTHORIZED:
                    throw new UnauthorizedError();
            }
        }
        return false;
    }
    /**
     * delete
     * @param sdelete
     */
    static async delete(sdelete) {
        const result = await NetFetch.postData('/json/species/delete', sdelete);
        if (result && result.statusCode) {
            switch (result.statusCode) {
                case StatusCodes.OK:
                    return true;
                case StatusCodes.UNAUTHORIZED:
                    throw new UnauthorizedError();
                default:
                    if (result.msg) {
                        throw Error(result.msg);
                    }
            }
        }
        return false;
    }
}
