/**
 * StatusCodes
 */
export var StatusCodes;
(function (StatusCodes) {
    StatusCodes[StatusCodes["OK"] = 200] = "OK";
    StatusCodes[StatusCodes["UNAUTHORIZED"] = 401] = "UNAUTHORIZED";
    StatusCodes[StatusCodes["FORBIDDEN"] = 403] = "FORBIDDEN";
    StatusCodes[StatusCodes["INTERNAL_ERROR"] = 500] = "INTERNAL_ERROR";
})(StatusCodes || (StatusCodes = {}));
