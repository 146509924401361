export class UtilSelect {
    /**
     * getSelectStr
     * @param select
     */
    static getSelectStr(select) {
        switch (select) {
            case 0:
                return 'No';
            case 1:
                return 'Yes';
        }
        return 'Unknown';
    }
}
