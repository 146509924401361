/**
 * UtilDistanceCoast
 */
export class UtilDistanceCoast {
    /**
     * meterToM
     * @param value
     * @param round
     */
    static meterToM(value, round) {
        const mValue = 0.5399568035 * (value / 1000);
        if (round) {
            return mValue.toFixed(2);
        }
        return `${mValue}`;
    }
}
