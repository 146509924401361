import { NetFetch } from '../Net/NetFetch';
/**
 * Login
 */
export class Login {
    /**
     * login
     * @param _email
     * @param _password
     */
    static async login(_email, _password) {
        const response = await NetFetch.postData('/json/login', {
            email: _email,
            password: _password
        });
        if (response) {
            if (response.success) {
                return true;
            }
            throw new Error(response.error);
        }
        return false;
    }
    /**
     * isLogin
     */
    static async isLogin() {
        const response = await NetFetch.getData('/json/islogin');
        if (response.status) {
            return true;
        }
        return false;
    }
    /**
     * logout
     */
    static async logout() {
        const response = await NetFetch.getData('/json/logout');
        if (response.status) {
            return true;
        }
        return false;
    }
}
