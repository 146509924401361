import { ModalDialog, ModalDialogType } from 'bambooo';
/**
 * TourEditModal
 */
export class TourEditModal extends ModalDialog {
    /**
     * constructor
     * @param elementObject
     */
    constructor(elementObject) {
        super(elementObject, 'tourmodaldialog', ModalDialogType.large);
    }
}
