import { NetFetch } from '../Net/NetFetch';
import { UnauthorizedError } from './Error/UnauthorizedError';
import { StatusCodes } from './Status/StatusCodes';
/**
 * EncounterCategories
 */
export class EncounterCategories {
    /**
     * getList
     */
    static async getList() {
        const result = await NetFetch.getData('/json/encountercategories/list');
        if (result && result.statusCode) {
            switch (result.statusCode) {
                case StatusCodes.OK: {
                    const response = result;
                    return response.list;
                }
                case StatusCodes.UNAUTHORIZED:
                    throw new UnauthorizedError();
            }
        }
        return null;
    }
}
