import { LeftNavbarPushmenu, Wrapper } from 'bambooo';
/**
 * BasePage
 */
export class BasePage {
    /**
     * constructor
     */
    constructor() {
        /**
         * const Title
         * @private
         */
        this.TITLE = 'MWPA';
        /**
         * const logo
         * @private
         */
        this.LOGO = 'images/whale-ico.png';
        /**
         * Wrapper
         * @protected
         */
        this._wrapper = new Wrapper();
        /**
         * page name
         * @protected
         */
        this._name = 'base';
        /**
         * load page function
         * @protected
         */
        this._loadPageFn = null;
        /**
         * on load table
         * @protected
         */
        this._onLoadTable = null;
        // eslint-disable-next-line no-new
        new LeftNavbarPushmenu(this._wrapper.getNavbar().getLeftNavbar());
        const preloader = this._wrapper.getPreloader();
        preloader.setTitle(this.TITLE);
        preloader.setImage(this.LOGO);
        const mainSidebar = this._wrapper.getMainSidebar();
        mainSidebar.getLogo().setTitle(this.TITLE);
        mainSidebar.getLogo().setImage(this.LOGO);
        mainSidebar.getLogo().getElement().find('img').css({
            'background-color': '#ffffff'
        });
        this._toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000
        });
    }
    /**
     * getWrapper
     */
    getWrapper() {
        return this._wrapper;
    }
    /**
     * getName
     */
    getName() {
        return this._name;
    }
    /**
     * setLoadPageFn
     * @param aloadPageFn
     */
    setLoadPageFn(aloadPageFn) {
        this._loadPageFn = aloadPageFn;
    }
    /**
     * loadContent
     */
    loadContent() {
        // load content overwrite
    }
    /**
     * unloadContent
     */
    unloadContent() {
        // unload content overwrite
    }
}
