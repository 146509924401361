import { NetFetch } from '../Net/NetFetch';
import { UnauthorizedError } from './Error/UnauthorizedError';
import { StatusCodes } from './Status/StatusCodes';
/**
 * Sightings
 */
export class Sightings {
    /**
     * getList
     */
    static async getList(filter) {
        let data = {};
        if (filter) {
            data = filter;
        }
        const result = await NetFetch.postData('/json/sightings/list', data);
        if (result && result.statusCode) {
            switch (result.statusCode) {
                case StatusCodes.OK:
                    return result;
                case StatusCodes.UNAUTHORIZED:
                    throw new UnauthorizedError();
            }
        }
        return null;
    }
    /**
     * delete
     * @param sighting
     */
    static async delete(sighting) {
        const result = await NetFetch.postData('/json/sightings/delete', sighting);
        if (result && result.statusCode) {
            switch (result.statusCode) {
                case StatusCodes.OK:
                    return true;
                case StatusCodes.UNAUTHORIZED:
                    throw new UnauthorizedError();
            }
        }
        return false;
    }
    /**
     * setDateByGPS
     */
    static async setDateByGPS() {
        const result = await NetFetch.getData('/json/sightings/setdatebygps');
        if (result && result.statusCode) {
            switch (result.statusCode) {
                case StatusCodes.OK:
                    return result.data;
                case StatusCodes.UNAUTHORIZED:
                    throw new UnauthorizedError();
            }
        }
        return null;
    }
    static async getWeather(sighting) {
        const result = await NetFetch.postData('/json/sightings/weather', sighting);
        console.log(result);
    }
}
