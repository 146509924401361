import { FormGroup, FormRow, ModalDialog, ModalDialogType, Textarea } from 'bambooo';
/**
 * Sighting deleted modal dialog.
 */
export class SightingDeletedModal extends ModalDialog {
    /**
     * Constructor from Dialog.
     * @param {Element} elementObject
     */
    constructor(elementObject) {
        super(elementObject, 'sightingdeletemodaldialog', ModalDialogType.large);
        /**
         * ID of entry.
         * @member {number|null}
         */
        this._id = null;
        /**
         * on save click
         * @protected
         */
        this._onSaveClick = null;
        const bodyCard = jQuery('<div class="card-body"/>').appendTo(this._body);
        const rowFirst = new FormRow(bodyCard);
        const groupDesc = new FormGroup(rowFirst.createCol(6), 'Are you sure you want to delete the sighting? Please describe:');
        this._descriptionArea = new Textarea(groupDesc);
        // buttons -----------------------------------------------------------------------------------------------------
        jQuery('<button type="button" class="btn btn-default" data-dismiss="modal">Close</button>').appendTo(this._footer);
        const btnSave = jQuery('<button type="button" class="btn btn-primary">Save changes</button>').appendTo(this._footer);
        btnSave.on('click', () => {
            if (this._onSaveClick !== null) {
                this._onSaveClick();
            }
        });
    }
    /**
     * getId
     */
    getId() {
        return this._id;
    }
    /**
     * Set the ID of entry.
     * @param {number|null} id - ID of entry.
     */
    setId(id) {
        this._id = id;
    }
    /**
     * Return the description by textarea input.
     * @returns {string}
     */
    getDescription() {
        return this._descriptionArea.getValue();
    }
    /**
     * Set the description to textarea.
     * @param {string} description
     */
    setDescription(description) {
        this._descriptionArea.setValue(description);
    }
    /**
     * resetValues
     */
    resetValues() {
        this.setId(null);
        this.setDescription('');
    }
    /**
     * setOnSave
     * @param onSave
     */
    setOnSave(onSave) {
        this._onSaveClick = onSave;
    }
}
