import { Lang_DE } from '../langs/Lang_DE';
import { Lang_EN } from '../langs/Lang_EN';
/**
 * Lang
 */
export class Lang {
    /**
     * init
     */
    static init() {
        Lang.addStore(new Lang_EN());
        Lang.addStore(new Lang_DE());
    }
    /**
     * setStoreLangSelect
     * @param lang
     */
    static setStoreLangSelect(lang) {
        localStorage.setItem('nwpa_lang', lang);
    }
    /**
     * getStoreLangSelect
     */
    static getStoreLangSelect() {
        return localStorage.getItem('nwpa_lang');
    }
    /**
     * addStore
     * @param alang
     */
    static addStore(alang) {
        Lang._store[alang.getClassName()] = alang;
    }
    /**
     * i
     * @param langPack
     */
    static i(langPack = null) {
        if (Lang._instance) {
            return Lang._instance;
        }
        let lp = langPack;
        if (!lp) {
            lp = 'Lang_EN';
        }
        Lang._instance = new Lang(lp);
        return Lang._instance;
    }
    /**
     * constructor
     * @param langPack
     * @param defaultLangPack
     */
    constructor(langPack, defaultLangPack = 'Lang_EN') {
        this._defaultLang = Lang._store[defaultLangPack];
        this._lang = Lang._store[langPack];
    }
    /**
     * setCurrentLang
     * @param alang
     */
    setCurrentLang(alang) {
        this._lang = alang;
    }
    /**
     * l
     * @param content
     */
    l(content) {
        let rcontent = null;
        if (this._lang) {
            rcontent = this._lang.l(content);
        }
        if (!rcontent) {
            rcontent = this._defaultLang.l(content);
        }
        if (rcontent) {
            return rcontent;
        }
        return content;
    }
    /**
     * lAll
     */
    lAll() {
        jQuery('body').find('[lang="1"]').each((_index, element) => {
            const attrLangValue = jQuery(element).attr('lang-value');
            if (attrLangValue) {
                jQuery(element).empty().append(this.l(attrLangValue));
            }
        });
    }
}
Lang._store = {};
// eslint-disable-next-line no-use-before-define
Lang._instance = null;
// init
Lang.init();
