/**
 * UtilRedirect
 */
export class UtilRedirect {
    /**
     * toLogin
     */
    static toLogin() {
        window.location.replace('/login.html');
    }
}
