import { FormGroup, InputBottemBorderOnly2, ModalDialog, ModalDialogType } from 'bambooo';
/**
 * SpeciesEditModal
 */
export class SpeciesEditModal extends ModalDialog {
    /**
     * constructor
     * @param elementObject
     */
    constructor(elementObject) {
        super(elementObject, 'speciesmodaldialog', ModalDialogType.large);
        /**
         * id of entry
         * @protected
         */
        this._id = null;
        /**
         * click save fn
         * @protected
         */
        this._onSaveClick = null;
        const bodyCard = jQuery('<div class="card-body"/>').appendTo(this._body);
        const groupName = new FormGroup(bodyCard, 'Specie-Name');
        this._inputName = new InputBottemBorderOnly2(groupName);
        this._inputName.setPlaceholder('Name of specie');
        jQuery('<button type="button" class="btn btn-default" data-dismiss="modal">Close</button>').appendTo(this._footer);
        const btnSave = jQuery('<button type="button" class="btn btn-primary">Save</button>').appendTo(this._footer);
        btnSave.on('click', () => {
            if (this._onSaveClick !== null) {
                this._onSaveClick();
            }
        });
    }
    /**
     * getId
     */
    getId() {
        return this._id;
    }
    /**
     * setId
     * @param id
     */
    setId(id) {
        this._id = id;
    }
    /**
     * getName
     */
    getName() {
        return this._inputName.getValue();
    }
    /**
     * setName
     * @param name
     */
    setName(name) {
        this._inputName.setValue(name);
    }
    /**
     * resetValues
     */
    resetValues() {
        this.setId(null);
        this.setName('');
    }
    /**
     * setOnSave
     * @param onSave
     */
    setOnSave(onSave) {
        this._onSaveClick = onSave;
    }
}
