/**
 * Lang_EN
 */
export class Lang_EN {
    constructor() {
        /**
         * lang content
         * @private
         */
        this._content = {
            title: 'MWPA',
            login_title: '<b>MWPA</b>',
            copyrightname: '<strong>Mammal watching. Processing. Analysing. - Project MWPA 2022 <a href="https://github.com/stefanwerfling/mwpa" target="_blank">on Github</a>.</strong> &nbsp; A project of the  <a href="https://m-e-e-r.de/" target="_blank">M.e.e.r e.V.</a> association',
            version: '<b>Version</b> 1.0.0'
        };
    }
    /**
     * getLangCode
     */
    getLangCode() {
        return 'en';
    }
    /**
     * getLangTitle
     */
    getLangTitle() {
        return 'English';
    }
    /**
     * getCountryCode
     */
    getCountryCode() {
        return 'us';
    }
    /**
     * l
     * @param acontent
     */
    l(acontent) {
        if (this._content[acontent]) {
            return this._content[acontent];
        }
        return null;
    }
    /**
     * getClassName
     */
    getClassName() {
        return 'Lang_EN';
    }
}
