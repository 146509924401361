import { NetFetch } from '../Net/NetFetch';
import { UnauthorizedError } from './Error/UnauthorizedError';
import { StatusCodes } from './Status/StatusCodes';
/**
 * Vehicle
 */
export class Vehicle {
    /**
     * getList
     */
    static async getList() {
        const result = await NetFetch.getData('/json/vehicle/list');
        if (result && result.statusCode) {
            switch (result.statusCode) {
                case StatusCodes.OK:
                    const response = result;
                    return response.list;
                case StatusCodes.UNAUTHORIZED:
                    throw new UnauthorizedError();
            }
        }
        return null;
    }
}
